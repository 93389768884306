import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import AuthorizationChecker from '../../../services/AuthorizationChecker.js';
import useInfiniteLoadingLogic from '../../../hooks/useInfiniteLogic.jsx';
import DynamicTableInfinite from '../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx';
import UserDetailsLogs from '../userDetail/UserDetailsLogs.jsx';
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import PropTypes from 'prop-types';
import { URLS } from '../../../services/axios';
import { useModal } from '../../../hooks/useModal.jsx';
import FormationSessionsByUserTableComponent from '../../formations/upload/formationSession/FormationSessionsByUserTableComponent.jsx';
import { useToast } from '../../../hooks/useToast.jsx';
import withURLParamsTableComponent from '../../../HOC/withURLParamsTableComponent.jsx';

const FormationSessionsByUserTableComponentWithParams = withURLParamsTableComponent(FormationSessionsByUserTableComponent);

const UserTableComponent = ({ users, full = false, isLoading = false, withParams,
    isShowRemoveBOAccessColumn=false }) => {
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientActionSlugs && clientActionSlugs['readOne'] ? clientActionSlugs['readOne']:null;
    const { isParent } = useStoreState(state => state.auth);
    const { getIsParent } = useStoreActions(state => state.auth);

    const { fetchFormationSessionsByIdForUser, deleteUser } = useStoreActions(actions => actions.users);

    const { sharedSlugs } = useStoreState(actions => actions.params);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();

    const [sortedUsers, setSortedUsers] = useState([]);
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        if (isParent === null) {
            getIsParent();
        }
    }, []);

    useEffect(() => {
        setShouldSort(true)
        setSortedUsers([...users]);
    }, [users]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedUsers, usersToDisplay, setUsersToDisplay, 100);

    if (!users.length) return <></>;

    const createDeleteModalBOAccess = (user) => {
        if(AuthorizationChecker.hasDeleteRights('users')) {
            setModalData({
                ...modalData,
                header: <>Retrait de l'accès BO</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir retirer l'accès BO de <b>{user.email}</b>&nbsp;?</h5>,
                resetButton: 'Retirer',
                cancelButton: 'Annuler',
                onReset: () => {
                    setIsSending(true);
                    deleteUser({ uniqueId: user.uniqueId })
                        .then(() => {
                            toast.success()
                            setModalShow(false);
                        })
                        .finally(() => setIsSending(false));
                },
            });
            setModalShow(true);
        } 
    }

    const displayDeleteUserAccess = (user) => user?.playlistCount == 0 && !user?.isAdmin
        ? <Button variant="danger" size="sm" className="m-0 p-2"
            onClick={()=>createDeleteModalBOAccess(user)}>
                <i className="fas fa-trash-alt"></i>
            </Button>
        : <OverlayTrigger
            placement="right"
            overlay={<Tooltip><div>L'accès utilisateur⋅rice ne peut pas être retiré car:
                <ul>
                    {user.playlistCount > 0 && <li>il⋅elle a des playlists</li>}
                    {user.isAdmin && <li>il⋅elle a un accès admin</li>}
                </ul>
            </div></Tooltip>}>
            <span>
                <Button disabled variant="danger" size="sm" className="m-0 p-2">
                    <i className="fas fa-trash-alt"></i>
                </Button>
            </span>
        </OverlayTrigger>;

    const sortType = [
        {
            value : 'email', label : 'E-mail',
            test : HelperMetiers360.isArrayContainsValue(users, "email"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.email, b.email),
            display: (user) => user.email
        },
        {
            value: 'lastName', label: "Nom",
            test : HelperMetiers360.isArrayContainsValue(users, "lastName"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.lastName, b.lastName),
            display: (user) => user.lastName ? user.lastName : '-'
        },
        {
            value: 'firstName', label: "Prénom",
            test : HelperMetiers360.isArrayContainsValue(users, "firstName"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.firstName, b.firstName),
            display: (user) => user.firstName ? user.firstName : '-'
        },
        {
            value: 'job', label: "Fonction",
            test: AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(users, "job"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.job === "" ? null : a.job, b.job === "" ? null : b.job),
            display: (user) => user.job && user.job !== "" ? user.job : '-'
        },
        {
            value: 'inviteTokenCreateDate', label: "Invitation",
            test : HelperMetiers360.isArrayContainsValue(users, "inviteTokenCreateDate"),
            method: (a, b) => {
                if (!a.inviteTokenCreateDate && !b.inviteTokenCreateDate)
                    return 0;
                else if (!a.inviteTokenCreateDate)
                    return 1
                else if (!b.inviteTokenCreateDate)
                    return -1
                
                return new Date(b.inviteTokenCreateDate) - new Date(a.inviteTokenCreateDate)

            },
            display: (user) => user.inviteTokenCreateDate ? HelperMetiers360.getdisplayDateType(user.inviteTokenCreateDate, 'day') : '-'
        },
        {
            value: 'lastLogin', label: "Dernière connexion",
            test : users.some(u => 'lastLogin' in u),
            method: (a, b) => {
                if (a.lastLogin && b.lastLogin) return new Date(b.lastLogin) - new Date(a.lastLogin);
                if (!a.lastLogin && b.lastLogin)
                    return 1;
                if (a.lastLogin && !b.lastLogin)
                    return -1;
                if (!a.lastLogin && !b.lastLogin) {
                    const aLabel = a.inviteToken ? 'En attente':'Jamais';
                    const bLabel = b.inviteToken ? 'En attente':'Jamais';
                    return aLabel.localeCompare(bLabel);

                }
            },
            display: (user) => {
                if(user.lastLogin) return HelperMetiers360.getdisplayDateType(user.lastLogin);
                if(user.inviteToken) return 'En attente';
                return 'Jamais';
            },
            getRedirectUrl: (user) => {
                if (!user.inviteToken)
                    return false;
                const url = URLS.BASE_URL + `/${sharedSlugs.activation}/${user.inviteToken}`;
                return url;
            }
        },
        {
            value: 'allLogs', label: "Connexions",
            test : HelperMetiers360.isArrayContainsValue(users, "allLogs"),
            method: (a, b) => (b.allLogs - a.allLogs),
            display: (user) => {
            if(!user.allLogs) return '0';
                return ((isParent || AuthorizationChecker.isAdmin()) ?
                    <Button as="a" bsPrefix={'no-btn'} 
                onClick={() => {createLogsModal(user)}}>{user.allLogs ? user.allLogs : '0'}</Button>
                    : user.allLogs
                )
            },
            flatDisplay: (user) => user.allLogs ? user.allLogs : '0'
        },
        {
            value: 'logs', label: "Connexions au loader",
            test : HelperMetiers360.isArrayContainsValue(users, "logs"),
            method: (a, b) => (b.logs - a.logs),
            display: (user) => user.logs ? user.logs : '0'
        },
        {
            value: 'formations', label: "Inscriptions webinaires",
            test : HelperMetiers360.isArrayContainsValue(users, "formationSessionsCount"),
            method: (a, b) => (b.formationSessionsCount - a.formationSessionsCount),
            display: (user) => {
                if (!user.formationSessionsCount) return '0';
            return <Button as="a" bsPrefix={'no-btn'} 
                onClick={() => {createSessionsModal(user)}}>{user.formationSessionsCount}</Button>
            },
            flatDisplay: (user) => user.formationSessionsCount ?? "0",
        },
        {
            value: 'clientBackoffice', label: "Back-office client",
            test: AuthorizationChecker.isAdmin(),
            method: (a, b) => (a.isAdmin === b.isAdmin)? 0 : a.isAdmin ? -1 : 1,
            display: (user) => user.isAdmin ? '-' : <i className="fa fa-external-link-alt"></i>,
            flatDisplay: () => null,
            getRedirectUrl: (user) => {
                if (user.isAdmin)
                    return false;

                return URLS.API_URL + `/backoffice/?_switch_user=${user.email}`;
            }
        },
        {
            value: 'delete', label: "Retrait accès BO",
            test: isShowRemoveBOAccessColumn && AuthorizationChecker.isAdmin(),
            method: (a, b) => (a.isAdmin === b.isAdmin) ? 0 : a.isAdmin ? -1 : 1,
            display: (user) => displayDeleteUserAccess(user),
            flatDisplay: () => null
        }        
    ]

    const createLogsModal = (user) => {
        setModalData({
            ...modalData,
            header: <>Logs de connexion de <b>{user.email}</b></>,
            content: <UserDetailsLogs user={user} />,  
            size: 'xl', 
        });
        setModalShow(true);
    }

    const createSessionsModal = (user) => {
        fetchFormationSessionsByIdForUser(user.uniqueId).then((res)=>{
            setModalData({
                ...modalData,
                header: <>Webinaires</>,
                content: <FormationSessionsByUserTableComponentWithParams formationSessionsList={res} isParent={isParent} />,  
                size: 'xl', 
            });
    
            setModalShow(true);
        })
    }

    if (full) {
        sortType.push(
            {
                value: 'clientName', label: "Établissement",
                test : HelperMetiers360.isArrayContainsValue(users, "clientName"),
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (user) => {
                    if(!user.clientName || !user.clientUniqueId) return '-';
                    return user.clientUniqueId && oneClientSlug ?
                        <Link to={oneClientSlug.replace(':uniqueId', user.clientUniqueId)}>{user.clientName}</Link>
                        : <>{user.clientName}</>;
                },
                flatDisplay: (user) => user.clientName
            },
            {
                value: 'tagOperations', label: "Opé M360",
                test : HelperMetiers360.isArrayContainsValue(users, "tagOperations"),
                method: (a, b) => HelperMetiers360.sortStringArray(a.tagOperations?.map(tagOpe => tagOpe.name), b.tagOperations?.map(tagOpe => tagOpe.name)),
                display: (user) => user.tagOperations?.map(tagOpe => tagOpe.name)?.join(", ")
            },
            {
                value: 'categories', label: "Catégories",
                test : HelperMetiers360.isArrayContainsValue(users, "categories"),
                method: (a, b) => HelperMetiers360.sortStringArray(a.categories, b.categories),
                display: (user) => user.categories?.join(", ")
            },
            {
                value: 'status', label: "Statut",
                test: HelperMetiers360.isArrayContainsValue(users, "status"),
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.status, b.status),
                display: (user) => user.status
            },
            {
                value : 'subscriptionEndDate', label : 'Date de fin abo.',
                test : HelperMetiers360.isArrayContainsValue(users, "subscriptionEndDate"),
                method: (a, b) => new Date(b.subscriptionEndDate) - new Date(a.subscriptionEndDate),
                display: (user) => HelperMetiers360.getdisplayDateType(user.subscriptionEndDate, 'day')
            }
            );
    };

    return <>
        { usersToDisplay.length > 0 
            && <DynamicTableInfinite
                contentTable = {usersToDisplay}
                contentSort = {sortType}
                valueInitSort= 'lastLogin'
                index = 'uniqueId'
                fetchData={fetchData}
                hasMore={hasMore}
                setSortedContent={setSortedUsers}
                sortedContent={sortedUsers}
                sortState={[shouldSort, setShouldSort]}
                isLoading={isLoading}
            withParams={withParams}
                tableName="usersTable"
                filename="utilisateurs" />
        }
        {modalComponent}
    </>;
}

UserTableComponent.propTypes = {
    users: PropTypes.array.isRequired,
    full: PropTypes.bool,
    isLoading: PropTypes.bool,
    withParams: PropTypes.bool,
    isShowRemoveBOAccessColumn: PropTypes.bool,
}

export default UserTableComponent;