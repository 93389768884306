import React, { useEffect, useState } from "react";
import { useStoreState } from 'easy-peasy';
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import { useModal } from "../../hooks/useModal.jsx";


import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import HeadsetDetailComponent from "./headsetDetail/HeadsetDetailComponent.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import PropTypes from 'prop-types';

/**
 * This function is used to display the list of headsets
 * @param props - the props passed to the component.
 * @returns A component.
 */
const HeadsetTableComponent = (props) =>  {
    const { headsets = [], returnHeadsets = false, returnHeadsetTypesExpected = null, withParams } = props;

    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfHeadsetOwnership } = BACKOFFICE_DEFAULT_VALUES;

    const [headsetToDisplay, setHeadsetToDisplay] = useState([]);
    const [sortedHeadset, setSortedHeadset] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        setShouldSort(true);
        headsets && setSortedHeadset([...headsets]);
    }, [headsets]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedHeadset, headsetToDisplay, setHeadsetToDisplay, 100);


    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    if(headsets?.length === 0) {
        return null;
    }

    const playlistsDisplay = (headset) => headset.playlistUniqueId 
        ? <i className="far fa-check-circle" />
        : <>-</>;

    const displayOwnershipHeadset = (ownership) => returnHeadsets && ownership !== "rent"
            ? <span className="text-danger fw-bold">{typeOfHeadsetOwnership[ownership]}</span>
            : typeOfHeadsetOwnership[ownership];

    const displayTypeHeadset = (headset) => headset.typeOfHeadsetName
        ? returnHeadsets && returnHeadsetTypesExpected && ('typeOfHeadsetRef' in headset) && !returnHeadsetTypesExpected?.includes(headset.typeOfHeadsetRef)
            ? <span className="text-danger fw-bold">{headset.typeOfHeadsetName}</span>
            : headset.typeOfHeadsetName
        : <>-</>;

    const sortType = 
        [
            {
                value : 'name', label : 'Nom',
                test : HelperMetiers360.isArrayContainsValue(headsets, "headsetName"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.headsetName, b.headsetName),
                display: (headset) => headset.headsetName ? headset.headsetName:'-'
            },
            {
                value : 'type', label : 'Casque',
                test : HelperMetiers360.isArrayContainsValue(headsets, "typeOfHeadsetName"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.typeOfHeadsetName, b.typeOfHeadsetName),
                display: (headset) => displayTypeHeadset(headset),
                flatDisplay: (headset) => headset.typeOfHeadsetName
            },
            {
                value : 'SN', label : 'N° de série',
                test : HelperMetiers360.isArrayContainsValue(headsets, "deviceId"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.deviceId, b.deviceId),
                display: (headset) => headset.deviceId
            },
            {
                value : 'macAdress', label : 'Adresse Mac',
                test : HelperMetiers360.isArrayContainsValue(headsets, "macAdress"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.macAdress, b.macAdress),
                display: (headset) => headset.macAdress
            },
            {
                value : 'inventoryNumber', label : 'Num inventaire',
                test : HelperMetiers360.isArrayContainsValue(headsets, "inventoryNumber"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.inventoryNumber, b.inventoryNumber),
                display: (headset) => headset.inventoryNumber
            },
            {
                value : 'clientName', label : 'Établissement',
                test : HelperMetiers360.isArrayContainsValue(headsets, "clientName"),
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (headset) => headset.clientName
            },
            {
                value : 'ownership', label : 'Type',
                test : HelperMetiers360.isArrayContainsValue(headsets, "ownership"),
                method: (a, b) => {
                    const ownerA = a.ownership ? typeOfHeadsetOwnership[a.ownership]:null ;
                    const ownerB = b.ownership ? typeOfHeadsetOwnership[b.ownership]:null;
                    return HelperMetiers360.localeCompareWithNullable(ownerA, ownerB)
                },
                display: (headset) => displayOwnershipHeadset(headset.ownership)
            },
            {
                value : 'lastPing', label : 'Dernière connexion',
                test : HelperMetiers360.isArrayContainsValue(headsets, "lastPing"),
                method: (a, b) => new Date(b.lastPing) - new Date(a.lastPing),
                display: (headset) => headset.lastPing ? HelperMetiers360.getdisplayDateType(headset.lastPing):'-'
            },
            {
                value : 'batteryLeft', label : 'Batterie',
                test : HelperMetiers360.isArrayContainsValue(headsets, "batteryLeft"),
                method: (a, b) => b.batteryLeft - a.batteryLeft,
                display: (headset) => headset.batteryLeft ? headset.batteryLeft:'?'
            },
            {
                value : 'appVersion', label : 'Version app',
                test : HelperMetiers360.isArrayContainsValue(headsets, "appVersion"),
                method: (a, b) =>  HelperMetiers360.convertAppVersionForSort(b.appVersion) - HelperMetiers360.convertAppVersionForSort(a.appVersion),
                display: (headset) => headset.appVersion ? headset.appVersion:'?'
            },
            {
                value : 'deviceOSVersion', label : 'Version OS',
                test : HelperMetiers360.isArrayContainsValue(headsets, "deviceOSVersion"),
                method: (a, b) =>  HelperMetiers360.localeCompareWithNullable(a.deviceOSVersion, b.deviceOSVersion),
                display: (headset) => headset.deviceOSVersion ?? '?'
            },
            {
                value : 'mem', label : 'Mémoire utilisée',
                test : HelperMetiers360.isArrayContainsValue(headsets, "mem") || HelperMetiers360.isArrayContainsValue(headsets, "storageTotal"),
                method: (a, b) => b.mem-a.mem,
                display: (headset) => {
                    let usedMem = headset.mem ? `${headset.mem} %` : 'n.c.';
                    let totalMem = headset.storageTotal 
                        ? `${Math.round(headset.storageTotal * 0.000000001)} GB` 
                        : 'n.c.';
                    if (usedMem === 'n.c.' && totalMem === 'n.c.') {
                        return 'n.c.';
                    }        
                    return `${usedMem} de ${totalMem}`;
                }
            },
            {
                value : 'playlist', label : 'Playlist',
                test : HelperMetiers360.isArrayContainsValue(headsets, "playlistUniqueId"),
                method: (a, b) => {

                    const playlistA = a.playlistUniqueId;
                    const playlistB = b.playlistUniqueId;
                    if (playlistA && playlistB) {
                        return 0;
                    }
                    else if (playlistA) return -1;
                    else if (playlistB) return 1;
                    else return 0;
                },
                display: (headset) => playlistsDisplay(headset),
                flatDisplay : (headset) => headset.playlistUniqueId ? 'X':'-'
            },
            {
                value: 'tagOperations', label: "Opé M360",
                test : HelperMetiers360.isArrayContainsValue(headsets, "tagOperations"),
                method: (a, b) => HelperMetiers360.sortStringArray(a.tagOperations?.map(tagOpe => tagOpe.name), b.tagOperations?.map(tagOpe => tagOpe.name)),
                display: (headset) => headset.tagOperations?.map(tagOpe => tagOpe.name)?.join(", ")
            },
            {
                value: 'categories', label: "Catégories",
                test : HelperMetiers360.isArrayContainsValue(headsets, "categories"),
                method: (a, b) => HelperMetiers360.sortStringArray(a.categories, b.categories),
                display: (headset) => headset.categories?.join(", ")
            },
            {
                value: 'hasParent', label: "Enfant",
                test : HelperMetiers360.isArrayContainsValue(headsets, "parentUniqueId"),
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.parentUniqueId, b.parentUniqueId),
                display: (headset) => headset.parentUniqueId ? 'X': '-'
            },
            {
                value: 'createdDate', label: "Date d'entrée",
                test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(headsets, "createdAt"),
                method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                display: (headset) => headset.createdAt ? HelperMetiers360.getdisplayDateType(headset.createdAt.date ? headset.createdAt.date:headset.createdAt, 'day') : '-'
            },
            {
                value: 'subscriptionEndDate', label: "Date fin abo.",
                test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(headsets, "createdAt"),
                method: (a, b) => new Date(b.subscriptionEndDate) - new Date(a.subscriptionEndDate),
                display: (headset) => headset.subscriptionEndDate ? HelperMetiers360.getdisplayDateType(headset.subscriptionEndDate, 'day') : '-'
            },
            {
                value : 'returnedTimestamp', label : 'Date de retour',
                test : returnHeadsets && HelperMetiers360.isArrayContainsValue(headsets, "returnedTimestamp"),
                method: (a, b) => new Date(b.returnedTimestamp) - new Date(a.returnedTimestamp),
                display: (headset) => headset.returnedTimestamp ? HelperMetiers360.timestampToDisplayDate(headset.returnedTimestamp):'-'
            }
        ];

        const handleClick = (selectedId) => {
            const selectedHeadset = headsets.find(
                (headset) => headset.uniqueId === selectedId
                );
            setModalData({
                ...modalData,
                header: 'Activités du casque : '+ (selectedHeadset.headsetName ? selectedHeadset.headsetName : selectedHeadset.deviceId ),
                content: <HeadsetDetailComponent headset={selectedHeadset} />,
                size: 'xl',
            });
            setModalShow(true);
        };

        return <>
            { headsetToDisplay.length > 0
                && <DynamicTableInfinite 
                    contentTable = {headsetToDisplay
                        .map(head => {
                            return {
                                ...head,
                                backgroundColor: head?.isOutOfOrder && AuthorizationChecker.isAdmin()
                                    ? '#FFA7A9' 
                                    : null
                            }
                        })}
                    contentSort = {sortType}
                    valueInitSort = "lastPing"
                    index = 'uniqueId'
                    handleClick={handleClick}
                    fetchData={fetchData}
                    hasMore={hasMore}
                    setSortedContent={setSortedHeadset}
                    sortedContent={sortedHeadset}
                    sortState={[shouldSort, setShouldSort]}
                withParams={withParams}
                    tableName="headsetsTable"
                    filename="parc_casques" />
            }
            {modalComponent}
        </>;
}

HeadsetTableComponent.propTypes = {
    headsets: PropTypes.array,
    returnHeadsets: PropTypes.bool,
    returnHeadsetTypesExpected: PropTypes.array,
    withParams: PropTypes.bool
}

export default HeadsetTableComponent