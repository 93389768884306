import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Container, Alert, Button, Image, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import AuthorizationChecker from "../../../services/AuthorizationChecker";

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import RomeComponent from '../../romes/RomeComponent.jsx';

import VideoPlayerComponent from './videoPlayer/index.jsx';
import { useModal } from '../../../hooks/useModal';
import { useToast } from '../../../hooks/useToast.jsx';

import './VideoDetailsComponent.scss';
import Loader from '../../widgets/Loader.jsx';
import VideoRomeComponent, { submitROMEVideoForm } from '../VideoRomeComponent.jsx';
import VideoCommentM360Component, { submitCommentM360VideoForm } from '../VideoCommentM360Component.jsx';
import ProfessionnalComponent from '../../professionnals/ProfessionnalComponent.jsx';
import useBackButton from '../../../hooks/useBackButton.jsx';

/**
 * 
 * @param {Object} video object with all the video data to be displayed on the page
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights 
 * @example
 * 
 * <VideoDetailsComponent video={video} update={updateSlug} {...}>
 */

const VideoDetailsComponent = (props) => {
    const { video, videoStories, isFetchingStories, videoPedagogicModules, isFetchingAllPedagogicModules, videoRomes, returnLink } = props;

    const videoSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const { deleteVideo, editVideoROME, editVideoCommentM360 } = useStoreActions(actions => actions.videos);

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const toast = useToast();

    const { readOne: readOneGroup } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));
    const { readOne: readOneStory } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const { readOne: readOnePedagogicModule } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));

    const [key, setKey] = useState('videoDetails');
    const [isGroupsOpen, setIsGroupsOpen] = useState(true);
    const [isPedagogicModulesOpen, setIsPedagogicModulesOpen] = useState(true);
    const [isStoriesOpen, setIsStoriesOpen] = useState(true);

    const navigate = useNavigate();

    const editorContentRef = useRef(video?.description);

    const goToClient = (uniqueIdClient) => {
        navigate(clientsSlugs.readOne.replace(':uniqueId', uniqueIdClient))
    }
    const { backButtonComponent } = useBackButton({ mediaType: "videos", className: "w-auto" })

    const romeList = video.romes?.length > 0 ?
        <RomeComponent
            romeList={video.romes}
            isClickable={AuthorizationChecker.hasReadOneRights('romes')}
        />
        : <>Pas de codes ROME enregistrés</>;
    const prosList = video.pros?.length > 0 ?
        <ProfessionnalComponent pros={video.pros} fromVideoUniqueId={video.uniqueId} />
        : <span className='ms-1 mt-3'>Pas de professionnel⋅les enregistré⋅es</span>;

    const playerPreference = useStoreState(state => state.preferences.videoPlayerType);
    const setPlayerPreference = useStoreActions(actions => actions.preferences.setVideoPlayerType);

    const subtitleItems = video.subtitles?.length
        ? video.subtitles.map((subtitle) => (
            <Button variant='light' className="ms-2" key={subtitle.url} action href={subtitle.url}>
                <i className="mr-2 fa fa-download" />{subtitle.lang == 'en' ? "Anglais" : "Français"}
            </Button>
        ))
        : <span>Pas de sous-titres enregistrés</span>

    const producersList = video?.producers?.length > 0
        ? video.producers?.map((p, i) =>
            <>
                <a key={`link${p.id}`} href="#"
                    onClick={(e) => { e.preventDefault(); goToClient(p.id); }}>
                    {p.clientName}
                </a>
                {(i === video.producers.length - 1) ? null : <span>,&nbsp;&nbsp;</span>}
            </>)
        : null;

    const clientsList = video?.clients?.length > 0
        ? video.clients?.map((p, i) =>
            <>
                <a key={`link${p.id}`} href="#"
                    onClick={(e) => { e.preventDefault(); goToClient(p.uniqueId); }}>
                    {p.name}
                </a>
                {(i === video.clients.length - 1) ? null : <span>,&nbsp;&nbsp;</span>}
            </>)
        : null;

    const isUsedInGroup = video.groups !== null;
    const isUsedInStory = !isFetchingStories && videoStories.find(story => story.fromVideoUniqueId === video.uniqueId) !== undefined;
    const isUsedInPedagogicModule = videoPedagogicModules?.length > 0;
    const canBeDeleted = !isUsedInGroup && !isUsedInPedagogicModule;

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression de l'expérience immersive</>,
            content: <>
                {isUsedInGroup &&
                    <Alert variant="danger">
                        <h5>Cette expérience immersive est utilisée dans <b>{video.groups.length}</b>
                            &nbsp;{video.groups.length > 1 ? 'espaces immersifs' : 'espace immersif'}</h5>
                    </Alert>}
                {isUsedInPedagogicModule &&
                    <Alert variant="danger">
                        <h5>Cette expérience immersive est utilisée dans <b>{videoPedagogicModules.length}</b>
                            &nbsp;{videoPedagogicModules.length > 1 ? 'modules pédagogiques' : 'module pédagogique'}</h5>
                    </Alert>}
                {canBeDeleted ?
                    <h5>Êtes-vous sûr⋅e de vouloir supprimer l'expérience immersive <b>{video.videoName}</b>&nbsp;?</h5>
                    : <h5>Veuillez supprimer l'expérience immersive de ce(s) containers pour pouvoir continuer</h5>}
            </>,
            resetButton: 'Supprimer',
            cancelButton: canBeDeleted && 'Annuler',
            onReset: canBeDeleted ?
                () => {
                    deleteVideo({ uniqueId: video.uniqueId })
                        .then(() => {
                            navigate(returnLink)
                        });
                }
                : null,
        });
        setModalShow(true);
    }

    const validateRome = () => {
        submitROMEVideoForm({ uniqueId: video.uniqueId, action: editVideoROME, editorContentRef })
            .then(() => {
                toast.success();
                setModalShow(false);
            })
    }
    const editRomeModal = () => {
        setModalData({
            ...modalData,
            header: <>Édition des codes ROME et des professionnel⋅les associé⋅es à l'expérience immersive</>,
            content: <VideoRomeComponent video={video} editorContentRef={editorContentRef} />,
            cancelButton: 'Annuler',
            onValidate: validateRome,
            size: 'xl'
        });
        setModalShow(true);
    }

    const validateCommentM360 = () => {
        submitCommentM360VideoForm({ uniqueId: video.uniqueId, action: editVideoCommentM360, editorContentRef })
            .then(() => {
                toast.success();
                setModalShow(false);
            })
    }
    const editCommentM360Modal = () => {
        setModalData({
            ...modalData,
            header: <>Édition commentaire M360 associé à l'expérience immersive</>,
            content: <VideoCommentM360Component video={video} editorContentRef={editorContentRef} />,
            cancelButton: 'Annuler',
            onValidate: validateCommentM360,
            size: 'xl'
        });
        setModalShow(true);
    }

    const isAttributeToDisplay = video.producers?.length > 0
        || (AuthorizationChecker.isAdmin() && (video.clients?.length > 0 || video.fileSize || video.position));

    const videoDetails = <Row>
        <Col>
            <div className="video_details_player">
                <VideoPlayerComponent
                    videoById={video}
                    playerTypePreferenceTuple={[playerPreference, setPlayerPreference]}
                    viewCount={video.viewCount}
                    headsetsCount={video.headsetsCount}
                />
            </div>
            {video.subtitles != null && <div className='my-4'>
                <strong>Sous-titres : </strong>
                {subtitleItems}
            </div>}
            {AuthorizationChecker.isAdmin()
                && (video.commentM360
                    ? <div className="rome-div">
                        <div className='d-flex justify-content-between mb-3'>
                        <strong>Commentaire :</strong>
                        <Button variant="secondary" onClick={editCommentM360Modal}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
                        </div>

                        <span className="comment_m360 text-muted" dangerouslySetInnerHTML={{ __html: video.commentM360 }}></span>
                    </div>
                    : <Button className="d-flex align-items-center justify-content-center" variant="success" onClick={editCommentM360Modal}>
                        <i className="fas fa-plus"></i> &nbsp;Ajouter un commentaire
                    </Button>)
            }
        </Col>
        <Col>
            <Row>
                {video.producers?.length > 0
                    && <div className='mb-2'>
                        <strong>Producteur{video.producers?.length > 1 ? "s" : ""} : </strong>
                        {producersList}
                    </div>}
            </Row>
            <Row>
                {(AuthorizationChecker.isAdmin() && video.clients?.length)
                    && <div className='mb-2'>
                        <strong>Client{video.clients?.length > 1 && 's'} ayant accès: </strong>
                        {clientsList}
                    </div>}
            </Row>
            <Row className={isAttributeToDisplay ? 'mb-2' : 'mb-2 mt-5'}>
                <Col>
                    {AuthorizationChecker.isAdmin() && video.fileSize != null
                        && <div>
                            <strong>Taille : </strong> {HelperMetiers360.formatBytes(video.fileSize)}
                        </div>}
                </Col>
                <Col>
                    {AuthorizationChecker.isAdmin() && video.position != null
                        && <div>
                            <strong>Position : </strong>{video.position}
                        </div>}
                </Col>
            </Row>
            {isAttributeToDisplay
                && <div className='d-flex justify-content-center mb-3'>
                    <hr width="90%" />
                </div>
            }

            <Row>
                <span dangerouslySetInnerHTML={{ __html: video.description }}></span>
            </Row>
        </Col>
    </Row>;

    const videoJobs = <Row>
        <div className='mb-5'>
            <div className='d-flex justify-content-between'>
                <h5>Métier{video.romes?.length > 1 && 's'} visible{video.romes?.length > 1 && 's'}</h5>
                {AuthorizationChecker.hasUpdateRights('videos') &&
                    <Button variant="secondary" onClick={editRomeModal}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
                }
            </div>
            <div className="mt-3">
                {romeList}
            </div>
        </div>
        <h5>Professionnel⋅le{video.romes?.length > 1 && 's'} rencontré⋅e{video.romes?.length > 1 && 's'}</h5>
        {prosList}
    </Row>;

    const videoAccessContent = <Row>
        <div className="associated_card">
            {isUsedInGroup &&
                <div className="associated_card_item">
                    <Card.Title
                        className="associated_card_title d-flex justify-content-between"
                        onClick={() => setIsGroupsOpen(!isGroupsOpen)}>
                        {video.groups?.length} {video.groups?.length > 1 ? "espaces immersifs" : "espace immersif"}

                        {isGroupsOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isGroupsOpen
                        && <CardGroupMediaComponent mediaType='groups' mediaList={video.groups} readOne={readOneGroup} />
                    }
                </div>
            }
            {(isUsedInPedagogicModule && !isFetchingAllPedagogicModules) &&
                <div className="associated_card_item">
                    <Card.Title className="associated_card_title d-flex justify-content-between" onClick={() => setIsPedagogicModulesOpen(!isPedagogicModulesOpen)}>
                        {videoPedagogicModules?.length} modules pédagogiques

                        {isPedagogicModulesOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isPedagogicModulesOpen
                        && <CardGroupMediaComponent
                            mediaType='pedagogicModules'
                            mediaList={videoPedagogicModules}
                            readOne={readOnePedagogicModule} />
                    }
                </div>
            }
            {(isUsedInStory && !isFetchingStories) &&
                <div className="associated_card_item">
                    <Card.Title className="associated_card_title d-flex justify-content-between" onClick={() => setIsStoriesOpen(!isStoriesOpen)}>
                        {videoStories?.length} stories

                        {isStoriesOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isStoriesOpen
                        && <CardGroupMediaComponent mediaType='stories' mediaList={videoStories} readOne={readOneStory} />
                    }
                </div>
            }
            {(isFetchingStories || isFetchingAllPedagogicModules) && <Loader />}
        </div>
    </Row>;

    return (
        <Container>
            <Row className='mb-5'>
                <Col className='col-md-auto' xs={12}>
                    {video?.links?.thumbnail
                        && <Image className="item_details-thumbnail" src={video.links.thumbnail} />}
                </Col>
                <Col>
                    <Row>
                        <h3 className='fw-bold w-auto'>{video?.videoName ?? "Détails de l'expérience immersive"}</h3>
                        {AuthorizationChecker.isAdmin() && video.private
                            && <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>Expérience immersive privée</Tooltip>}>
                                <span className="groupIcon"><i className="fas fa-lock"></i></span>
                            </OverlayTrigger>}
                        {AuthorizationChecker.isAdmin() && video?.isAlwaysVisible
                            && <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>Expérience immersive freemium (accessible sans abonnement)</Tooltip>}>
                                <span className="groupIcon ms-3"><i className="fab fa-creative-commons-nc-eu" /></span>
                            </OverlayTrigger>}
                    </Row>
                    <Row>
                        <h5>{video?.subHeading ?? ""}</h5>
                    </Row>
                    <Row className='h-50 align-items-end'>
                        {video.directAccessCode != null && (video.isDirectlyAccessible)
                            && <h6>Code d'accès direct : <span className='badge-code'>{video.directAccessCode}</span></h6>}
                    </Row>
                </Col>
                <Col className='d-flex flex-column justify-content-end align-top'>
                    <Row className='d-flex justify-content-end pe-3 h-50 align-items-start'>
                        {backButtonComponent}
                    </Row>
                    <Row className='d-flex justify-content-end pb-3'>
                        <p className='w-auto m-0 text-right'>
                            {video.releaseDate != null
                                && "Créée le : " + HelperMetiers360.getdisplayDateType(video.releaseDate, 'day')}
                            {video.updatedAt != null
                                && " - Dernière mise à jour le : " + HelperMetiers360.getdisplayDateType(video.updatedAt, 'day')}
                        </p>
                    </Row>
                    <Row className='p-0'>
                        {
                            (AuthorizationChecker.hasUpdateRights('videos') || AuthorizationChecker.hasDeleteRights('videos'))
                            && <div className="item_details_btn_actions d-flex justify-content-end">
                                {
                                    AuthorizationChecker.hasUpdateRights('videos') ?
                                        <Button variant="secondary" as={Link} to={videoSlugs.update.replace(':uniqueId', video && video.uniqueId)}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
                                        : ''
                                }
                                {AuthorizationChecker.hasDeleteRights('videos') ?
                                    <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
                                    : null}
                            </div>
                        }
                    </Row>
                </Col>
            </Row>

            <Tabs defaultActiveKey="videoDetails" id="video-details-tab" activeKey={key}
                onSelect={(k) => setKey(k)} className="d-flex mb-4" style={{ flexWrap: "wrap" }}>
                <Tab eventKey="videoDetails" title="Détails de l'expérience immersive" unmountOnExit={false}>
                    {videoDetails}
                </Tab>
                <Tab eventKey="jobsVideo" title="Métiers et professionnel⋅les" unmountOnExit={false}>
                    {videoJobs}
                </Tab>
                <Tab eventKey="accessVideo" title="Accès" unmountOnExit={false}>
                    {videoAccessContent}
                </Tab>
            </Tabs>

            {modalComponent}
        </Container>
    );
}

export default VideoDetailsComponent;
