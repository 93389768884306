import React, { useState, useEffect } from "react";
import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Button, Alert } from "react-bootstrap";

import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from "react-router-dom";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import Loader from "../../components/widgets/Loader.jsx";
import DownloadRomeVideosPDF from "../../components/widgets/generatePDFs/romesVideosList.jsx";
import DownloadVideosListPDF from "../../components/widgets/generatePDFs/videosList.jsx";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import VideoTableComponent from "../../components/videos/videosList/VideosTableComponent.jsx";
import VideoBoardComponent from "../../components/videos/videosList/VideoBoardComponent.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import DownloadJobsVideosPDF from "../../components/widgets/generatePDFs/jobsVideoTable.jsx";

import "./VideosPage.scss";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import TooltipInfo from "../../customComponents/TooltipInfo.jsx";

const VideoTableWithParams = withURLParamsTableComponent(VideoTableComponent);

const VideosPage = () => {

    const {allVideos, isFetchingAllVideos: isVideosLoading, isGradualFetchingAllVideos} = useCustomGetStoreState("videos");
    const { allRomes } = useCustomGetStoreState("romes");
    const { allJobAppellations, isFetchingAllJobAppellations } = useStoreState(state => state.romes);
    const { fetchAllJobAppellations } = useStoreActions(actions => actions.romes);

    const videoSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("videos"));
    const { fetchAllPropsForAllVideos} = useStoreActions(actions => actions.videos);

    // preferences from the store that is hydrated from local storage
    const viewStylePreference = useStoreState(state => state.preferences.videoListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setVideoListStyle);

    const videoBoardSortType = useStoreState(state => state.preferences.videoBoardSortType);
    const setvideoBoardSortType = useStoreActions(actions => actions.preferences.setvideoBoardSortType);

    const [filteredVideos, setFilteredVideos] = useState(allVideos);
    const [generateJobs, setGenerateJobs] = useState(false);
    const [generateUniverse, setGenerateUniverse] = useState(false);
    const [formattedJobAppellations, setFormattedJobAppellations] = useState([]);
    const [generateAppellationJobs, setGenerateAppellationJobs] = useState(false);

    const {searchInput, search} = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (nom, titre secondaire, description...)', delay: 400,
        width: videoSlugs.create ? "35vw" : "100%"
    })

    const viewStyleType=[
        {value : 'table', label:<i className="fas fa-table"></i>, view : <VideoTableWithParams videos = {filteredVideos} {...videoSlugs} />},
        {value : 'cards', label:<i className="fas fa-address-card"></i>, view :<VideoBoardComponent videos = {filteredVideos} sortTypePreferenceTuple={[videoBoardSortType, setvideoBoardSortType]} {...videoSlugs} />},
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    useEffect(() => {
        AuthorizationChecker.hasGetPropsForAllRights('videos') && fetchAllPropsForAllVideos();
    }, []);

	useEffect(() => {
        setFilteredVideos(allVideos)
	}, [allVideos]);


    useEffect(() => {
        if (!allJobAppellations?.length && !isFetchingAllJobAppellations) {
            fetchAllJobAppellations();
        }
    }, [allJobAppellations, isFetchingAllJobAppellations])



    useEffect(() => {
        setFilteredVideos(
           [...allVideos.filter(
                video => HelperMetiers360.isSearchInText(search, video.videoName)
                    || HelperMetiers360.isSearchInText(search, video.subHeading)
                    || HelperMetiers360.isSearchInText(search, video.description)
                    || (AuthorizationChecker.isAdmin() && HelperMetiers360.isSearchInText(search, video.commentM360))
                    || HelperMetiers360.isSearchInText(search, video.romes?.join())
                    || HelperMetiers360.isSearchInText(search, video.producers
                        ?.map(producer => producer.clientName)?.join())
            )]
        );

	}, [search, allVideos]);


    useEffect(() => {
        if (!isFetchingAllJobAppellations && !isVideosLoading
            && allJobAppellations.length !== 0 && allVideos.length !== 0
        ) {
            setFormattedJobAppellations(
                allJobAppellations.map(job => {
                    return {
                        ...job,
                        videos: allVideos.filter(video => job.videosUniqueId.includes(video.uniqueId))
                    }
                })
            );
        }
    }, [allJobAppellations, allVideos, isFetchingAllJobAppellations, isVideosLoading])


    const isNoVideos = !isVideosLoading && !allVideos.length
    const isNoResult = !isVideosLoading && allVideos.length && !filteredVideos.length;

    return <Container fluid>
            <Row>
            <h2 className="d-flex">
                Notre catalogue d'expériences immersives
                <TooltipInfo description="Explorez notre catalogue d'expériences immersives, découvrez les différents univers professionnels 
                    et les métiers qui y sont associés" />
            </h2>
            </Row>
            <Row className="mt-2 mb-4 d-flex justify-content-between align-items-center">
            <Col className="col-auto">
                { generateUniverse
                    ? <DownloadVideosListPDF videos={allVideos?.sort((a, b) => a.videoName.localeCompare(b.videoName))}/>
                    : <Button variant="primary" onClick={() => {setGenerateUniverse(true)}}
                        disabled={allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Catalogue
                    </Button>}
            </Col>
            <Col className="col-auto">
                {generateAppellationJobs
                    ? <DownloadJobsVideosPDF title="Par métier" buttonText="Par métier"
                        fileName="tableau-experiences-par-metier"
                        jobs={formattedJobAppellations?.sort((a, b) => a.label.localeCompare(b.label))} />
                    : <Button variant="primary" onClick={() => { setGenerateAppellationJobs(true) }}
                        disabled={formattedJobAppellations?.length === 0 || allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Par métier
                    </Button>}
            </Col >
            <Col className="col-auto">
                { generateJobs
                    ? <DownloadRomeVideosPDF romes={allRomes} videos={allVideos} />
                    : <Button variant="primary" onClick={() => {setGenerateJobs(true)}} 
                            disabled={allRomes?.length === 0 || allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Par code ROME
                    </Button>}
            </Col >
            </Row>
            <Row className="item-page">
            <Col className={videoSlugs.create ? "m360-col3-start" : ""}>
                    {searchInput}
                </Col>
            <Col className={videoSlugs.create ? "m360-col3-center" : "m360-col3-end"}>
                    <ToggleButtonGroup type="radio" name="option" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                </Col>
            {videoSlugs.create
                && <Col className="m360-col3-end">
                    <Button variant="success" as={Link} to={videoSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
                </Col>}
            </Row>
           
            <Row>
                { (isVideosLoading || isGradualFetchingAllVideos) && <Loader /> }
                { filteredVideos.length > 0 && viewStyleType.filter(type => type.value===viewStyle)[0].view  }
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucune expérience immersive ne correspond à votre recherche</Alert>}
            {isNoVideos && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucune expérience immersive</Alert>}
            </Row>
        </Container>;
};

export default VideosPage;