import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import headsetMenuContent from '../../images/lab_headset_menu.png';
import labImage from '../../images/lab_tester.jpg';
import './TesterComponent.scss'

const TesterComponent = () => {
    return <>
        <Row>
            <Col>
                <h4>Bienvenue au Lab !</h4>
                <h5 className="mt-4">Vous faites partie du Lab, notre communauté d’utilisateur·ices qui ont envie de partager leurs avis et bonnes idées.</h5>
                <div className="text-lab">
                    <p className="fw-bold mb-1 mt-4">En tant que bêta-testeur ou bêta-testeuse, vous pouvez avoir accès à :</p>
                    <ul>
                        <li>de nouvelles fonctionnalités ;</li>
                        <li>de nouveaux formats d'expériences immersives ;</li>
                        <li>de nouvelles modalités d'accompagnement ;</li>
                        <li>et bien d’autres choses encore au fil des sujets dont nous voulons discuter avec vous !</li>
                    </ul>
                    <p className="mt-4">Prenons le temps d’échanger et réfléchissons ensemble aux outils et ressources les plus adaptés aux besoins de vos publics. Peut-être que certaines pistes que vous aurez proposées verront le jour !</p>
                </div>
            </Col>

            <Col xl={12} xxl={6} className="col-img-lab">
                <img src={labImage} className="lab-image" />
            </Col>
        </Row>
        <Row>
            <div className="text-lab">
                <h4>Les tests en cours :</h4>
                <ul>
                    <li>Juillet 2024 - Août 2024 : test de nouveaux espaces dans le casque VR</li>
                </ul>
                <p>Nous souhaitons enrichir la page d’accueil de l’appli Métiers360 dans les casques VR, nous sommes preneurs de vos retours !</p>
                <p className="fw-bold mb-1">2 nouveaux espaces sont accessibles en exclusivité si vous&nbsp;
                    <a target="blank"
                        href="https://metiers360.freshdesk.com/support/solutions/articles/101000500354-installation-du-loader"
                    >mettez à jour</a> vos casques VR :</p>
                <ul>
                    <li>L'espace immersif “Je découvre des métiers par intérêt” permet de visionner des expériences immersives en s’appuyant sur la lecture d’image ;</li>
                    <li>L'espace immersif “Exploration guidée” permet d’être orienté·e dans le choix de l’expérience immersive à visionner grâce à quelques questions.</li>
                </ul>
                <img className="ms-2 mb-3 lab-image" src={headsetMenuContent} />
                <br />
                <p className="fw-bold mb-1">Modalité de test :</p>
                <ul className="mb-0">
                    <li>Consultez ces 2 nouveaux espaces dans vos casques (et idéalement organisez un atelier avec vos publics à l’aide des espaces “Je découvre des métiers par intérêt” et “Exploration guidée”) ;</li>
                    <li>
                        Choisissez un créneau dans notre agenda pour nous transmettre votre retour d’expérience lors d’un court entretien (30 mn environ).
                    </li>
                </ul>
                <Button
                    className="ms-3 mt-2 mb-4"
                    variant="primary"
                    href="https://calendar.app.google/4ckGNCRacwFVUPuKA"
                    target="blank">
                    J'accède à l'agenda
                </Button>
                <br />
                <h4>Merci, nous avons hâte de voir ce que vous en pensez !</h4>
            </div>
        </Row>
    </>
}
export default TesterComponent;