import React, { useMemo, useState, useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button, Row, Col, Tabs, Tab } from "react-bootstrap";

import { useModal } from "../../../../hooks/useModal";

import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import {URLS} from '../../../../services/axios';

import ClientInfoTabComponent from "../ClientDetailTabs/ClientInfoTabComponent";
import ClientWhitelistTabComponent from "../ClientDetailTabs/ClientWhitelistTabComponent";
import ClientWebappTabComponent from "../ClientDetailTabs/ClientWebappTabComponent";
import ClientSubscriptionTabComponent from "../ClientDetailTabs/ClientSubscriptionTabComponent";
import ClientFormationTabComponent from "../ClientDetailTabs/ClientFormationTabComponent";
import ClientUserTabComponent from "../ClientDetailTabs/ClientUserTabComponent";
import ClientCRMDetailViewComponent from "../clientCRMDetailView/ClientCRMDetailViewcomponent";
import ClientGroupsTabComponent from "../ClientDetailTabs/ClientGroupsTabComponent";
import ClientInteractionsTabComponent from "../ClientDetailTabs/ClientInteractionsTabComponent/ClientInteractionsTabComponent";
import ClientAddressesTabComponent from "../ClientDetailTabs/ClientAddressesTabComponent.jsx";
import Loader from "../../../widgets/Loader.jsx";

import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import { useNavigate } from "react-router-dom";
import ClientParenthoodTabComponent from "../ClientDetailTabs/ClientParenthoodTabComponent.jsx";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState.jsx";
import CardGroupMediaComponent from "../../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx";

const ClientDetailAdminViewComponent = (props) => {
    const { clientById, allClients } = props;
    const {apiData} = useStoreState(state => state.actionSlugs);
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState("videos");
    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const { isParent } = useStoreState(state => state.auth);
    const { getIsParent } = useStoreActions(state => state.auth);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    const [key, setKey] = useState(AuthorizationChecker.isAdmin() ? 'clientDetails' : 'subscriptionTab');

    const downloadCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getHeadsetActivityByCSV.url.replace('ClientID',clientById.uniqueId).replace('activityID','all');
        window.open(urlCSV);
    }

    const downloadwebappActiviyCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getwebappActivityByCSV.url.replace('ClientID',clientById.uniqueId);
        window.open(urlCSV);
    }

    // Parenthood
    const navigate = useNavigate();
    const goToClient = (uniqueIdClient) => {
        navigate(clientsSlugs.readOne.replace(':uniqueId', uniqueIdClient))
    }

    const parent = useMemo(() => 
        allClients?.length > 0 && clientById?.clientParent && 
            allClients.find(client => client.uniqueId === clientById.clientParent)
    , [allClients, clientById]);

    const parentListComponent =  <div className="parent-list">
        <div>
            <strong><br />Parent :<br /></strong>
        </div>
        <div className="children-list-container">
            {parent
                ? <a href="#" onClick={() => goToClient(parent.uniqueId)}>{parent.clientName}</a>
                : "0"}
        </div>
    </div>;

    const childrenList = useMemo(() => 
        allClients?.length > 0 && clientById?.clientChildren.length > 0
            ? allClients
                .filter(client => clientById.clientChildren.includes(client.uniqueId))
                .sort((a, b) => {
                    if (a.clientName > b.clientName)
                        return 1;
                    if (a.clientName < b.clientName)
                        return -1;
                    return 0;
                })
                .map( child => {
                    return <a key = {'child'+child.uniqueId} href="#" onClick={() => goToClient(child.uniqueId)}>
                        {child.clientName}
                    </a>})
            : []
    , [allClients]);

    const childrenListComponent = <div className="children-list">
        <div>
            <strong><br />Liste des {childrenList.length > 0 && childrenList.length} enfants :<br /></strong>
        </div>
        <div className="children-list-container">
            {childrenList.length > 0 ? childrenList : "0"}
        </div>
    </div>;

    const producedVideos = useMemo(() => {
        return AuthorizationChecker.isAdmin()
            ? allVideos?.filter(video => video?.producers?.find(producer => producer.id == clientById.uniqueId))
            : []
    }, [allVideos]);

    useEffect(() => {
        if (isParent === null) {
            getIsParent();
        }
    }, []);

    return (<>
    {AuthorizationChecker.isAdmin() && <>
        <Row className="mb-3">
            <Col>
                <p>
                    Créé le : {HelperMetiers360.getdisplayDateType(clientById.createdAt.date)}
                    &nbsp;&nbsp;-&nbsp;
                    Dernière mise à jour le : {HelperMetiers360.getdisplayDateType(clientById.updatedAt.date)}
                </p>
            </Col>
            <Col className="d-flex justify-content-end">
                <Button onClick={downloadCSV} className="me-3"><i className="fas fa-download"/> Activité casques</Button>
                <Button onClick={downloadwebappActiviyCSV}><i className="fas fa-download"/> Activité webapp</Button>
            </Col>
        </Row>
    </>}
        
        
        <Row>
            <Col>
            {AuthorizationChecker.isAdmin() ?
                <Tabs defaultActiveKey="clientDetails" id="client-details-tab" activeKey={key} 
                    onSelect={(k) => setKey(k)} className="d-flex justify-content-center mb-4" style={{flexWrap : "wrap"}}>
                    <Tab eventKey="clientDetails" title="Informations" unmountOnExit={true}> 
                        <ClientInfoTabComponent clientById={clientById} setModalData={setModalData} modalData={modalData}
                            setModalShow={setModalShow} setIsSending={setIsSending} parentListComponent={parentListComponent} childrenListComponent={childrenListComponent} />
                    </Tab>
                    {clientById.clientChildren.length > 0 &&
                        <Tab eventKey="clientParenthood" title="Réseau" unmountOnExit={true}> 
                            <ClientParenthoodTabComponent clientById={clientById} allClients={allClients} childrenListComponent={childrenListComponent}
                                setModalData={setModalData} setIsSending={setIsSending} modalData={modalData} setModalShow={setModalShow} />
                        </Tab>
                    }
                    <Tab eventKey="subscriptionTab" title="Abonnements & casques" unmountOnExit={true}>
                        <ClientSubscriptionTabComponent client= {clientById} />
                    </Tab>
                    <Tab eventKey="CRMTab" title="Client CRM" unmountOnExit={true}>
                        <ClientCRMDetailViewComponent client={clientById} linkToClientPage={false} />
                    </Tab>
                    <Tab eventKey="interactions" title="Suivi" unmountOnExit={true}>
                        <ClientInteractionsTabComponent clientId= {clientById.uniqueId} />
                    </Tab>
                    <Tab eventKey="userTab" title="Utilisateur⋅rices" unmountOnExit={true}>
                        <ClientUserTabComponent setModalData={setModalData} setIsSending={setIsSending} modalData={modalData} setModalShow={setModalShow} clientById={clientById} />
                    </Tab>
                    <Tab eventKey="formationTab" title="Webinaires" unmountOnExit={true}>
                        <ClientFormationTabComponent client= {clientById} />
                    </Tab>
                    <Tab eventKey="webappTab" title="Webapp" unmountOnExit={true}>
                        <ClientWebappTabComponent setModalData={setModalData} setIsSending={setIsSending} modalData={modalData} setModalShow={setModalShow} />
                    </Tab>
                    <Tab eventKey="whitelist" title="WhiteLists" unmountOnExit={true}>
                        <ClientWhitelistTabComponent setModalData={setModalData} setIsSending={setIsSending} modalData={modalData} setModalShow={setModalShow} />
                    </Tab>
                    <Tab eventKey="groupsTab" title="Groupes accessibles" unmountOnExit={true}>
                        <ClientGroupsTabComponent client= {clientById} />
                    </Tab>
                        {producedVideos.length > 0
                            && <Tab eventKey="producedVideosTab" title="Expériences immersives produites" unmountOnExit={true} transition={isFetchingAllVideos}>
                                <CardGroupMediaComponent mediaType="video" mediaList={producedVideos} readOne={readOneVideo} isLink={true} />
                            </Tab>}
                </Tabs>
                    : isParent === null ?
                        <Loader />
                        : <Tabs defaultActiveKey="subscriptionTab" id="client-subscription-tab" activeKey={key} onSelect={(k) => setKey(k)} className="d-flex">
                <Tab eventKey="subscriptionTab" title="Abonnements & casques" unmountOnExit={true}>
                    <ClientSubscriptionTabComponent client= {clientById} fromAccountPage={true}/>
                </Tab>
                <Tab eventKey="userTab" title="Utilisateur⋅rices" unmountOnExit={true}>
                    <ClientUserTabComponent setModalData={setModalData} setIsSending={setIsSending} modalData={modalData} setModalShow={setModalShow} clientById={clientById} fromAccountPage={true}/>
                </Tab>
                            {isParent && <Tab eventKey="adressTab" title="Coordonnées" unmountOnExit={true}>
                                <ClientAddressesTabComponent clientById={clientById} />
                            </Tab>}
                </Tabs>
            }
            </Col>
        </Row>
        {modalComponent} 
    </>);
}
export default ClientDetailAdminViewComponent;